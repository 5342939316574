import React from "react"
import Layout from "../templates/main/layout"
import SEO from "../components/seo"
import CarList from "../components/carList"
import { useStaticRestData } from "../hooks/data"

const IndexPage = () => {
  const { allFormOrderCar, allCar } = useStaticRestData();
  return (
    <Layout>
      <SEO title="" />
      <CarList items={allCar.nodes} formData={allFormOrderCar} />
    </Layout>
  )
}

export default IndexPage